

































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class risk_management extends Vue {
  dialogVisible: Boolean = false;
  loading: Boolean = false;
  set_form: object = {
    is_set: "1",
    risk_type: "1",
    set_profit: 0,
  };
  set_price: object = {
    alert_prices: 5000,
    automatic_confirm: "0",
    message: "0",
    phone: "",
  };
  order_alert: string = "0";
  /** S Methods **/
  isSetChange() {
    return this.$message.warning("设置后，点击下方保存按钮才开始生效哦");
  }
  orderAlertChange(e: any) {
    this.loading = true;
    // api
    this.$message.warning("操作成功");
    this.loading = false;
  }
  setPrice() {
    this.dialogVisible = true;
  }
  // 点击表单提交
  onSubmit(formName: string) {
    // 验证表单格式是否正确
    const refs = this.$refs[formName] as HTMLFormElement;
    refs.validate((valid: boolean): any => {
      if (!valid) return;
      console.log("kkk");
      this.dialogVisible = false;
    });
  }
}
